<template>
  <ion-page>
    <toolbar redirect="home"></toolbar>
    <ion-content fullscreen scrollEvents="true" @ionScrollStart="state.showWelcome=false">
      <ion-grid v-if="state.valid" class="main-content">
        <ion-row class="ion-justify-content-around">
          <ion-col size-sm="12" size-md="10" size-lg="8">
            <transition name="fade">
              <div class="ion-margin ion-text-center welcome-text" v-if="state.showWelcome">
                <div class="card-title">Congratulations, your ad has been posted!</div>
                <div>
                  <ion-icon :icon="checkmarkCircleOutline" class="icon-success"/>
                </div>
                <div>
                  You might to add more details to your post to give our visitors better idea of what this is all about.
                </div>
              </div>
            </transition>
            <div class="ion-margin">
              <h1>{{ state.post.title }}</h1>
            </div>     
            <div class="divider ion-margin-start">
              <h5>Details</h5>
            </div>
            <ion-row class="searchbar">
              <ion-col>
                <ion-label class="ion-margin-start">Nearest MRT</ion-label>
                <ion-searchbar 
                  debounce="500" 
                  @ionChange="ionChange($event)"
                  placeholder="Search for MRT"
                  :icon="train"
                  @ionFocus="state.showOptions = true"
                  ></ion-searchbar>
                <ion-row class="ion-justify-content-between">
                  <ion-col>
                    <ion-chip v-for="item in state.selectedMRT" :key="item.val">
                      <ion-label>{{ item.name }}</ion-label>
                      <ion-icon 
                        name="close-circle"
                        @click="removeItem(item.val)"
                        ></ion-icon>            
                    </ion-chip>
                  </ion-col>
                  <ion-col size="size" v-if="state.showOptions" class="ion-align-items-center vertical-center">
                    <ion-text class="ion-padding-end">Close</ion-text>
                    <ion-icon 
                      :icon="chevronUpCircleOutline"
                      @click="state.showOptions=false"
                      size="large"></ion-icon>
                  </ion-col>
                </ion-row>
                <ion-row>
                <ion-col>
                  <ion-list class="searchlist" :class="{ hidden: !state.showOptions }">
                    <ion-item v-for="item in state.searchOptions" :key="item.val" :class="{ hidden: item.hide }" >
                      <ion-label>{{item.name}}</ion-label>
                      <ion-checkbox
                        slot="start"
                        :checked="item.checked"
                        :value="item.val"
                        color="primary"
                        @ionChange="selectChange($event)"
                      >
                      </ion-checkbox>        
                    </ion-item>
                  </ion-list>
                </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size-sm="6" size-md="4" size-lg="3">
                <ion-item>
                  <ion-label position="floating">Location</ion-label>
                  <ion-input v-model="state.location" maxlength="30"></ion-input>
                </ion-item>
              </ion-col>
              <ion-col size-sm="6" size-md="4" size-lg="3">
                <ion-item>
                  <ion-label position="floating">Postal Code</ion-label>
                  <ion-input v-model="state.postal_code"  maxlength="6"></ion-input>
                </ion-item>
              </ion-col>
              <ion-col size-sm="6" size-md="3">
                <ion-item>
                  <ion-label position="floating">Type</ion-label>
                  <ion-select placeholder="Select One"
                   v-model="state.unit_type">
                    <ion-select-option 
                      v-for="(option, index) in options.type" :key="index"
                     >{{ option }}</ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
            </ion-row>
            <div class="divider ion-margin-start">
              <h5>Features</h5>
            </div>
            <ion-row>
              <ion-col v-for="(feature, index) in options.features" :key="index">
                <ion-item class="feat-item" lines="none">
                  <ion-label>{{ feature.name }}</ion-label>
                  <ion-checkbox 
                    v-model="state.features[feature.name]"
                    slot="end"
                  ></ion-checkbox>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-col size-xs="12" size-sm="8" size-md="6">
                 <ion-button expand="full" @click="updatePost" class="update-btn">Update</ion-button>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>     
      <ion-grid v-if="!state.valid">
        <div class="card-title">Oh, no! The data is gone. Please try again.</div>
      </ion-grid>
      
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent,
         IonGrid, IonRow, IonCol,
         IonItem, 
         IonLabel, 
         IonIcon,
         IonSelect,
         IonSelectOption,
         IonList, 
         IonSearchbar,
         IonCheckbox,
         IonChip,
         IonText,
        // IonListHeader,
        IonInput, 
        // IonItemDivider
        // IonTextarea,
        // // IonRadio, 
        // IonRadioGroup,
        IonButton
       } from '@ionic/vue'
import { defineComponent, reactive,  onBeforeMount,
        onMounted, computed, inject, nextTick, onUpdated } from 'vue'
import Toolbar from '@/components/Toolbar.vue'
import { addIcons } from 'ionicons'
import { checkmarkCircleOutline, addCircleOutline, closeCircleOutline,
         trainOutline, train, closeOutline,
         chevronUpCircleOutline } from 'ionicons/icons'
// import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import { mrt } from '@/utils/mrt'
import { openToast } from '@/utils'
import axios from '@/utils/api'

export default defineComponent({
  name: 'CreatePostSecond',
  components: { 
        IonPage, IonContent,
        IonGrid, IonRow, IonCol,
        Toolbar,
        IonItem, 
        IonLabel, 
        IonIcon,
        IonSelect,
        IonSelectOption,
        IonList,
        IonSearchbar,
        IonCheckbox,
        IonChip,
        IonText,
        IonInput,
        IonButton
        // IonItemDivider
  },
  setup (props, ctx) {
    const route = useRoute()
    const router = useRouter()

    const loadSpin = inject('loadSpinner')
    const state = reactive({
      post: { title: "Title",
              category: {code : "property"},
            },
      showWelcome: true,
      valid: true,
      searchOptions: [],
      // selectedMRT: []
      selectedMRT: computed(() => {
        return state.searchOptions.filter(x => x.checked)
      }),
      showOptions: false,
      category: "property",
      unit_type: "HDB",    // eslint-disable-line
      postal_code: "",  // eslint-disable-line
      location: "",
      features: {
           "Aircon": true
        }
    })

    const api = `/${route.params.countrycode}/p/all/${route.params.id}/`
    const options = reactive({
      type: [],
      features: []
    })

    const features = {
        "property": [
          { name: "Aircon", value: true}, 
          { name: "Cooking", value: true}, 
          { name: "Internet", value: true}, 
          { name: "Owner Staying", value: false}, 
          { name: "PUB Included", value: false}, 
          { name: "Visitors Allowed", value: false}, 
        ]
      }

    const type = {
        "property": ["HDB", "Condo", "Landed", "Shophouse"]
      }
    
    onBeforeMount (() => {
      loadSpin.status = true
      console.time('oncreate')
    })
    
    onMounted (() => {
      console.timeEnd('oncreate')
      console.time('onMounted')
      loadSpin.status = true
      if ('item' in route.params) {
        state.post = JSON.parse(route.params.item)
        // console.log(state.post)
        // console.log(state.post.category)
        state.category = state.post.category.code
      } else {
        // console.log('fetching data from server')
        state.valid = true
        axios.get(api)
        .then(res => {
          state.post = res.data
          state.category = state.post.category.code 
          state.postal_code = state.post.postal_code // eslint-disable-line
          state.location = state.post.location
          state.unit_type = state.post.unit_type // eslint-disable-line
          document.title = `${state.post.title} Additional Details | Sukisan SG Pinoy Marketplace`
        })
        .catch(e => {
          console.log(e.error_message)
        })
      }
      options.type = type[state.category]
      options.features = features[state.category]

      options.features.forEach(feat => {
        state.features[feat.name] = feat.value
      })
      state.searchOptions = mrt.map(obj => obj.val ? { ...obj, checked: false, hide: false}: obj)
      // nextTick( () => {
      //   loadSpin.status = false
      //   console.timeEnd('onMounted')
      // })
    })

    onUpdated(() => {
      nextTick( () => {
        loadSpin.status = false
      })
    })

    function ionChange(evt) {
      const val = event.target.value
      // state.searchOptions = options.filter(x => {
      //   return x.text.toLowerCase().indexOf(val.toLowerCase()) > -1
      // })
      state.searchOptions = state.searchOptions.map(obj => obj.name.toLowerCase().indexOf(val.toLowerCase()) < 0 ? { ...obj, hide: true} : { ...obj, hide:false })
      // state.searchOptions = options.map(x => {
      //   x.val === "01" ? { ...x, hide: false } : x
      // })
    }

    function selectChange(event) {
      const mrt = event.detail.value
      const _checked = event.detail.checked
      state.searchOptions = state.searchOptions.map(obj => obj.val === mrt ? { ...obj, checked: _checked} : obj)
    }

    function removeItem(val) {
      state.searchOptions = state.searchOptions.map(obj => obj.val === val ? { ...obj, checked: false} : obj)
    }

    function updatePost() {
      const payload = {
        title: state.post.title,
        price: state.post.price,
        description: state.post.description,
        postal_code: state.postal_code, // eslint-disable-line
        unit_type: state.unit_type, // eslint-disable-line
        location: state.location,
        features: [],
        nearest_mrt: [], // eslint-disable-line
      }
      for (const feat in state.features) {
        payload.features.push({
          name : feat,
          value: state.features[feat]
          }
        )
      }
      //payload["location"] = state.selectedMRT
      state.selectedMRT.forEach(mrt => {
        payload.nearest_mrt.push({
          name: mrt['name'],
          code: mrt['val']
        })
        // console.log(mrt['name'])
      })
      // console.log(payload.nearest_mrt)
      loadSpin.status = true
      axios.put(api, payload)
      .then(async (res) => {
        loadSpin.status = false
        openToast("Post successfully updated")
        await router.push({ 
          name: 'itemDetail', 
          params: {
            id: route.params.id,
            category: state.post.category.code || 'all',
            countrycode: route.params.countrycode,
            redirect: 'home'
            // item: JSON.stringify(props.item)
          }
        })       
       })
      .catch(e => {
        loadSpin.status = false
        openToast(e.message)
      })
    }

    return { 
      state, 
      checkmarkCircleOutline,
      options,
      ionChange,
      selectChange,
      train,
      removeItem,
      closeOutline,
      closeCircleOutline,
      chevronUpCircleOutline,
      updatePost,
      mrt
      }
  }
})

</script>

<style scoped>

.searchlist {
  max-height: 30vh;
  overflow: scroll;
}
.main-content {
  background:white;
  color: var(--ion-color-primary-text);
}


.hidden {
  display:none;
}

.close-option {
  font-size: 32px;
}

.icon-success {
  font-size: 64px;
}

ion-checkbox {
    --size: 24px;
    --checkmark-width: 2px;
    --border-width: 1px;
    --border-radius: var(--ion-border-radius);
}

ion-checkbox::part(container) {
  padding:3px;
}
.divider {
  border-bottom: 1px solid gray
}

ion-searchbar {
  --background: whitesmoke !important;
  --border-radius: 10px !important;
  --color: var(--ion-color-primary-text-dark) !important;
  --icon-color: var(--ion-color-primary-text-dark) !important;
  --box-shadow: 0px 0px 0px #aaaaaa !important;
}

ion-button {
  --padding-top: 15px;
  --padding-bottom: 15px;
  --padding-start: 50px;
  --padding-end: 50px;
  --box-shadow: 0px 0px 0px #aaaaaa !important;  
}

ion-button::part(native) {
  width:100%;
  text-transform: initial;
  letter-spacing: initial;
  border-radius: 13px;
}

ion-checkbox {
    --size: 24px;
    --checkmark-width: 2px;
    --border-width: 1px;
    --border-radius: var(--ion-border-radius);
    margin:5px;
}
.feature ion-label{
  margin: 0px;
}

.feature ion-icol {
  margin: 0px;
}

.feat-item ion-item {
  --inner-padding-top: 0px;
  --inner-padding-bottom: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
}

ion-checkbox::part(container) {
  padding:3px;
  margin:0px;
}


</style>