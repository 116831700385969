
export const mrt = [
    { val: "NS1", name: "Jurong East" }, 
    { val: "NS2", name: "Bukit Batok" }, 
    { val: "NS3", name: "Bukit Gombak" }, 
    { val: "NS4", name: "Choa Chu Kang" }, 
    { val: "NS5", name: "Yew Tee" }, 
    { val: "NS7", name: "Kranji" }, 
    { val: "NS8", name: "Marsiling" }, 
    { val: "NS9", name: "Woodlands" }, 
    { val: "NS10", name: "Admiralty" }, 
    { val: "NS11", name: "Sembawang" }, 
    { val: "NS13", name: "Yishun" }, 
    { val: "NS14", name: "Khatib" }, 
    { val: "NS15", name: "Yio Chu Kang" }, 
    { val: "NS16", name: "Ang Mo Kio" }, 
    { val: "NS17", name: "Bishan" }, 
    { val: "NS18", name: "Braddell" }, 
    { val: "NS19", name: "Toa Payoh" }, 
    { val: "NS20", name: "Novena" }, 
    { val: "NS21", name: "Newton" }, 
    { val: "NS22", name: "Orchard" }, 
    { val: "NS23", name: "Somerset" }, 
    { val: "NS24", name: "Dhoby Ghaut" }, 
    { val: "NS25", name: "City Hall" }, 
    { val: "NS26", name: "Raffles Place" }, 
    { val: "NS27", name: "Marina Bay" }, 
    { val: "NS28", name: "Marina South Pier" }, 
    { val: "EW1", name: "Pasir Ris" }, 
    { val: "EW2", name: "Tampines" }, 
    { val: "EW3", name: "Simei" }, 
    { val: "EW4", name: "Tanah Merah" }, 
    { val: "EW5", name: "Bedok" }, 
    { val: "EW6", name: "Kembangan" }, 
    { val: "EW7", name: "Eunos" }, 
    { val: "EW8", name: "Paya Lebar" }, 
    { val: "EW9", name: "Aljunied" }, 
    { val: "EW10", name: "Kallang" }, 
    { val: "EW11", name: "Lavender" }, 
    { val: "EW12", name: "Bugis" }, 
    { val: "EW15", name: "Tanjong Pagar" }, 
    { val: "EW16", name: "Outram Park" }, 
    { val: "EW17", name: "Tiong Bahru" }, 
    { val: "EW18", name: "Redhill" }, 
    { val: "EW19", name: "Queenstown" }, 
    { val: "EW20", name: "Commonwealth" }, 
    { val: "EW21", name: "Buona Vista" }, 
    { val: "EW22", name: "Dover" }, 
    { val: "EW23", name: "Clementi" }, 
    { val: "EW25", name: "Chinese Garden" }, 
    { val: "EW26", name: "Lakeside" }, 
    { val: "EW27", name: "Boon Lay" }, 
    { val: "EW28", name: "Pioneer" }, 
    { val: "EW29", name: "Joo Koon" }, 
    { val: "EW30", name: "Gul Circle" }, 
    { val: "EW31", name: "Tuas Crescent" }, 
    { val: "EW32", name: "Tuas West Road" }, 
    { val: "EW33", name: "Tuas Link" }, 
    { val: "CG1", name: "Expo" }, 
    { val: "CG2", name: "Changi Airport" }, 
    { val: "NE1", name: "HarbourFront" }, 
    { val: "NE4", name: "Chinatown" }, 
    { val: "NE5", name: "Clarke Quay" }, 
    { val: "NE7", name: "Little India" }, 
    { val: "NE8", name: "Farrer Park" }, 
    { val: "NE9", name: "Boon Keng" }, 
    { val: "NE10", name: "Potong Pasir" }, 
    { val: "NE11", name: "Woodleigh" }, 
    { val: "NE12", name: "Serangoon" }, 
    { val: "NE13", name: "Kovan" }, 
    { val: "NE14", name: "Hougang" }, 
    { val: "NE15", name: "Buangkok" }, 
    { val: "NE16", name: "Sengkang" }, 
    { val: "NE17", name: "Punggol" }, 
    { val: "CC2", name: "Bras Basah" }, 
    { val: "CC3", name: "Esplanade" }, 
    { val: "CC4", name: "Promenade" }, 
    { val: "CC5", name: "Nicoll Highway" }, 
    { val: "CC6", name: "Stadium" }, 
    { val: "CC7", name: "Mountbatten" }, 
    { val: "CC8", name: "Dakota" }, 
    { val: "CC10", name: "MacPherson" }, 
    { val: "CC11", name: "Tai Seng" }, 
    { val: "CC12", name: "Bartley" }, 
    { val: "CC14", name: "Lorong Chuan" }, 
    { val: "CC16", name: "Marymount" }, 
    { val: "CC17", name: "Caldecott" }, 
    { val: "CC19", name: "Botanic Gardens" }, 
    { val: "CC20", name: "Farrer Road" }, 
    { val: "CC21", name: "Holland Village" }, 
    { val: "CC23", name: "one-north" }, 
    { val: "CC24", name: "Kent Ridge" }, 
    { val: "CC25", name: "Haw Par Villa" }, 
    { val: "CC26", name: "Pasir Panjang" }, 
    { val: "CC27", name: "Labrador Park" }, 
    { val: "CC28", name: "Telok Blangah" }, 
    { val: "CE1", name: "Bayfront" }, 
    { val: "DT1", name: "Bukit Panjang" }, 
    { val: "DT2", name: "Cashew" }, 
    { val: "DT3", name: "Hillview" }, 
    { val: "DT5", name: "Beauty World" }, 
    { val: "DT6", name: "King Albert Park" }, 
    { val: "DT7", name: "Sixth Avenue" }, 
    { val: "DT8", name: "Tan Kah Kee" }, 
    { val: "DT10", name: "Stevens" }, 
    { val: "DT13", name: "Rochor" }, 
    { val: "DT17", name: "Downtown" }, 
    { val: "DT18", name: "Telok Ayer" }, 
    { val: "DT20", name: "Fort Canning" }, 
    { val: "DT21", name: "Bencoolen" }, 
    { val: "DT22", name: "Jalan Besar" }, 
    { val: "DT23", name: "Bendemeer" }, 
    { val: "DT24", name: "Geylang Bahru" }, 
    { val: "DT25", name: "Mattar" }, 
    { val: "DT27", name: "Ubi" }, 
    { val: "DT28", name: "Kaki Bukit" }, 
    { val: "DT29", name: "Bedok North" }, 
    { val: "DT30", name: "Bedok Reservoir" }, 
    { val: "DT31", name: "Tampines West" }, 
    { val: "DT33", name: "Tampines East" }, 
    { val: "DT34", name: "Upper Changi" }, 
    { val: "BP2", name: "South View" }, 
    { val: "BP3", name: "Keat Hong" }, 
    { val: "BP4", name: "Teck Whye" }, 
    { val: "BP5", name: "Phoenix" }, 
    { val: "BP7", name: "Petir" }, 
    { val: "BP8", name: "Pending" }, 
    { val: "BP9", name: "Bangkit" }, 
    { val: "BP10", name: "Fajar" }, 
    { val: "BP11", name: "Segar" }, 
    { val: "BP12", name: "Jelapang" }, 
    { val: "BP13", name: "Senja" }, 
    { val: "BP14", name: "Ten Mile Junction" }, 
    { val: "SE1", name: "Compassvale" }, 
    { val: "SE2", name: "Rumbia" }, 
    { val: "SE3", name: "Bakau" }, 
    { val: "SE4", name: "Kangkar" }, 
    { val: "SE5", name: "Ranggung" }, 
    { val: "SW1", name: "Cheng Lim" }, 
    { val: "SW2", name: "Farmway" }, 
    { val: "SW3", name: "Kupang" }, 
    { val: "SW4", name: "Thanggam" }, 
    { val: "SW5", name: "Fernvale" }, 
    { val: "SW6", name: "Layar" }, 
    { val: "SW7", name: "Tongkang" }, 
    { val: "SW8", name: "Renjong" }, 
    { val: "PE1", name: "Cove" }, 
    { val: "PE2", name: "Meridian" }, 
    { val: "PE3", name: "Coral Edge" }, 
    { val: "PE4", name: "Riviera" }, 
    { val: "PE5", name: "Kadaloor" }, 
    { val: "PE6", name: "Oasis" }, 
    { val: "PE7", name: "Damai" }, 
    { val: "PW1", name: "Sam Kee" }, 
    { val: "PW2", name: "Teck Lee" }, 
    { val: "PW3", name: "Punggol Point" }, 
    { val: "PW4", name: "Samudera" }, 
    { val: "PW5", name: "Nibong" }, 
    { val: "PW6", name: "Sumang" }, 
    { val: "PW7", name: "Soo Teck" }, 
]
